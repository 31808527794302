import { createContext } from 'react';

import { Organisation } from '@src/hooks/useVerifyOrgination';
import { Modes, ModeType } from '@src/lib/styles/theme';

interface GlobalUIContextProps {
  mode: ModeType;
  setMode?: (val: Modes) => void;
}

export const GlobalUIContext = createContext<GlobalUIContextProps>({ mode: Modes.LIGHT });
export const GlobalOrgData = createContext<{ organisation?: Organisation; loading: boolean }>({
  organisation: undefined,
  loading: false,
});
