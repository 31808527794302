/* eslint-disable @typescript-eslint/no-explicit-any */
import { graphConfig } from '../config/authConfig';

// Fetch the list of users from Microsoft Graph
export async function callMsGraph(accessToken: string): Promise<any> {
  const headers: Headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options: RequestInit = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphUsersListEndPoint, options)
    .then(response => response.json())
    .catch(error => console.error('Error calling Graph API:', error));
}

// Fetch events for a specific user from Microsoft Graph
export async function callUserEventsByDateMsGraph(
  accessToken: string,
  userId: string,
  startDateTime: string,
  endDateTime: string
): Promise<any> {
  const headers: Headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options: RequestInit = {
    method: 'GET',
    headers: headers,
  };

  // Build the URL for fetching events within the specific date range
  const url = `${graphConfig.graphUsersListEndPoint}/${userId}/calendarView?startDateTime=${encodeURIComponent(
    startDateTime
  )}&endDateTime=${encodeURIComponent(endDateTime)}`;

  return fetch(url, options)
    .then(response => response.json())
    .catch(error => console.error('Error fetching user events for specific date:', error));
}
