import { useCallback, useEffect, useState } from 'react';

import { getOrganisationByOrg } from '@src/api/organisation';

export interface Organisation {
  email: string;
  id: string;
  logo: { filename: string; imageKey: string };
  name: string;
  org_id: string;
  phoneNumber: string;
}

export const useVerifyOrganisation = () => {
  const [organisation, setOrganisation] = useState<Organisation>();
  const [loading, setLoading] = useState(false);

  const location = window.location;

  const handleFetchOrg = useCallback(async () => {
    setLoading(true);

    try {
      const org = await getOrganisationByOrg({ orgID: location?.pathname.split('/')[1] });
      setOrganisation(org);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [location?.pathname]);

  useEffect(() => {
    handleFetchOrg();
  }, [handleFetchOrg]);

  return {
    organisation,
    loading,
  };
};
