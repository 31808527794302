import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

import { roomBooking } from '@src/api/rooms';
import { GlobalOrgData } from '@src/contexts/GlobalUIContext';
import { Heading } from '@src/screens/Home/style';

interface FormDialogProps {
  handleClose: () => void;
  scrollToBottom: () => void;
  onSubmit: (data: { userId: string; alternativeName?: string }) => void;
}

const StyledButton = styled(Button)`
  margin: 8px;
`;

const FormDialog: React.FC<FormDialogProps> = ({ handleClose, onSubmit, scrollToBottom }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userName, setUserName] = useState('');
  const [adminId, setAdminId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { organisation } = useContext(GlobalOrgData);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const res = await roomBooking(phoneNumber, organisation?.id || '');
      localStorage.setItem('phoneNumber', phoneNumber);

      if (['administrators', 'super_admin'].includes(res.role)) {
        setAdminId(res.id);
      } else {
        onSubmit({ userId: res?.id });
        handleClose();
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
      console.log('error', error);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ userId: adminId, alternativeName: userName });
    handleClose();
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/\D/g, '');

    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;

    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;

    setPhoneNumber(value.slice(0, 12));
  };

  useEffect(() => {
    if (adminId) {
      // Use a timeout to allow the DOM to update before scrolling
      setTimeout(() => {
        // scrollToBottom();
      }, 800);
    }
  }, [adminId, scrollToBottom]);

  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        background: 'white',
      }}
    >
      <Heading style={{ margin: '20px 0', textAlign: 'center' }}>Enter Mobile Number</Heading>
      <form
        onSubmit={adminId ? handleSubmit : handleFormSubmit}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <TextField
          autoFocus={!adminId}
          required
          id='phoneNumber'
          name='phoneNumber'
          inputProps={{
            style: {
              fontSize: '25px',
              fontWeight: '550',
              textAlign: 'center',
            },
          }}
          type='tel'
          size='small'
          variant='outlined'
          value={phoneNumber}
          onChange={handlePhoneChange}
        />
        {adminId && (
          <TextField
            autoFocus
            id='userName'
            name='userName'
            onFocus={() => {
              scrollToBottom();
            }}
            inputProps={{
              style: {
                fontSize: '25px',
                fontWeight: '550',
                textAlign: 'center',
              },
            }}
            placeholder='Chandler Dinh'
            type='text'
            size='small'
            variant='outlined'
            margin='normal'
            value={userName.toUpperCase()}
            onChange={({ target: { value } }) => setUserName(value)}
            sx={{
              '& .MuiInputBase-input::placeholder': {
                color: '#B0BEC5',
                opacity: 0.5,
              },
            }}
          />
        )}
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton variant='contained' onClick={handleClose} color='secondary'>
            Cancel
          </StyledButton>
          <StyledButton type='submit' variant='contained'>
            Submit
          </StyledButton>
        </DialogActions>
      </form>
    </div>
  );
};

export default FormDialog;
