import { ThemeProvider } from 'styled-components';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import Loader from './components/Loader';
import { msalConfig } from './config/authConfig';
import { GlobalOrgData } from './contexts/GlobalUIContext';
import { useVerifyOrganisation } from './hooks/useVerifyOrgination';
import { muiTheme } from './lib/styles/muiTheme';
import getTheme, { Modes } from './lib/styles/theme';
import App from './App';

const msalInstance = new PublicClientApplication(msalConfig);

const AppWrapper = () => {
  const { loading, organisation } = useVerifyOrganisation();
  const theme = getTheme(Modes.LIGHT);

  if (loading) return <Loader />;

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <GlobalOrgData.Provider value={{ loading, organisation }}>
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          </GlobalOrgData.Provider>
        </ThemeProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppWrapper;
