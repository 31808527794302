const UiRoutes = {
  BOOKING: '/lewisroca/booking',
  LOGIN: '/login',
  VISITOR_HELP: '/lewisroca/visitor-help',
  PARKING_DIRECTION: '/lewisroca/parking-direction',
  MY_BOOKING: '/lewisroca/my-bookings',
  BOOKING_DEMO: '/demo/booking',
  VISITOR_HELP_DEMO: '/demo/visitor-help',
  PARKING_DIRECTION_DEMO: '/demo/parking-direction',
  MY_BOOKING_DEMO: '/demo/my-bookings',
};

export { UiRoutes };

export const roomSizes = [
  { name: '1 person', id: '1 person' },
  { name: '2 persons', id: '2 persons' },
  { name: '4 persons', id: '4 persons' },
  { name: '6 persons', id: '6 persons' },
  { name: '8 persons', id: '8 persons' },
  { name: '10 persons', id: '10 persons' },
  { name: '12 persons', id: '12 persons' },
  { name: '14 persons', id: '14 persons' },
  { name: '20+ persons', id: '20+ persons' },
];

export const mediaTypes = [
  { name: 'none', id: 'none' },
  { name: 'audio-video', id: 'audio-video' },
  { name: 'video-only', id: 'video-only' },
  { name: 'audio-only', id: 'audio-only' },
];

export const time = [
  { name: '7:00 am', id: '7:00am' },
  { name: '8:00 am', id: '8:00am' },
  { name: '9:00 am', id: '9:00am' },
  { name: '10:00 am', id: '10:00am' },
  { name: '11:00 am', id: '11:00am' },
  { name: '12:00 pm', id: '12:00pm' },
  { name: '1:00 pm', id: '1:00pm' },
  { name: '2:00 pm', id: '2:00pm' },
  { name: '3:00 pm', id: '3:00pm' },
  { name: '4:00 pm', id: '4:00pm' },
  { name: '5:00 pm', id: '5:00pm' },
  { name: '6:00 pm', id: '6:00pm' },
];

export const conferenceCities = [
  { name: 'Hotel', id: 'hotel', line2: '1-person' },
  { name: 'Conference', id: 'conference', line2: '2+ persons' },
];

export const servicesOptions = [
  { name: 'Meals', id: 'meals' },
  { name: 'None', id: 'none' },
  { name: 'Beverages', id: 'beverages', subCheckBox: ['Water', 'Soda', ' Iced tea', 'Coffee', 'Ice Bucket'] },
  {
    name: 'IT support',
    id: 'itSupport',
    subCheckBox: [
      'ONLY audio-voice setup for conference call',
      'BOTH audio-voice conference setup AND video-presentation setup',
    ],
  },
];

export const dateOptions = [
  { name: 'Single Day', id: 'singleDaySingleBooking', secondName: 'Single Room' },
  { name: 'Single day', id: 'singleDayMultiBooking', isDisable: false, secondName: 'Multiple rooms' },
  { name: 'Multiple Days', id: 'multipleDays', isDisable: true },
  { name: 'Single ', id: 'single recurring Days', isDisable: true, secondName: 'Recurring Days' },
  { name: 'Multiple ', id: 'Multiple Recurring Days', isDisable: true, secondName: 'Recurring Days' },
];

export const floorsOption = [
  { name: '1 Floor', id: '1' },
  { name: '2 Floor', id: '2' },
  { name: '4 Floor', id: '4' },
  { name: '6 Floor', id: '6' },
  { name: '8 Floor', id: '8' },
  { name: '10 Floor', id: '10' },
  { name: '12+ Floor', id: '12' },
];
