/* eslint-disable @typescript-eslint/no-explicit-any */
import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export const roomBooking = (phoneNumber: string, orgID: string): Promise<any> => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.BOOK_ROOM}`,
    data: { phoneNumber },
  });
};

export const varifyOtp = (otp: string) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.VARIFY_OTP}`,
    data: { otp },
  });
};

export const sendFeedback = (feedback: { positive: string; negative: string }) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.SEND_FEEDBACK}`,
    data: { ...feedback },
  });
};

export const getFloorNumber = (
  location: string,
  orgID: string
): Promise<{ floorDetails: { floorNumber: number; floorMap?: string }[]; floorNotFound: boolean }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.FLOOR_NUMBER}/${location}`,
    config: {
      params: {
        orgID,
      },
    },
  });
};

export const getRoomById = (roomID: string, orgID: string): Promise<any> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ROOM}/${roomID}`,
  });
};

export const getUserByName = (name: string, orgID: string): Promise<any> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_USER_BY_NAME}/${name}`,
  });
};
